export const MAJORS = [
  "Aerospace Engineering",
  "Agricultural Business",
  "Agricultural Communication",
  "Agricultural Science",
  "Agricultural Systems Management",
  "Animal Science",
  "Anthropology and Geography",
  "Architectural Engineering",
  "Architecture",
  "Art and Design",
  "BioResource and Agricultural Engineering",
  "Biochemistry",
  "Biological Sciences",
  "Biomedical Engineering",
  "Business Administration",
  "Chemistry",
  "Child Development",
  "City and Regional Planning",
  "Civil Engineering",
  "Communication Studies",
  "Comparative Ethnic Studies",
  "Computer Engineering",
  "Computer Science",
  "Construction Management",
  "Dairy Science",
  "Economics",
  "Electrical Engineering",
  "English",
  "Environmental Earth and Soil Sciences",
  "Environmental Engineering",
  "Environmental Management and Protection",
  "Food Science",
  "Forest and Fire Sciences",
  "General Engineering",
  "Graphic Communication",
  "History",
  "Industrial Engineering",
  "Industrial Technology and Packaging",
  "Interdisciplinary Studies",
  "Journalism",
  "Kinesiology",
  "Landscape Architecture",
  "Liberal Arts and Engineering Studies",
  "Liberal Studies",
  "Manufacturing Engineering",
  "Marine Sciences",
  "Materials Engineering",
  "Mathematics",
  "Mechanical Engineering",
  "Microbiology",
  "Music",
  "Nutrition",
  "Philosophy",
  "Physics - BA",
  "Physics - BS",
  "Plant Sciences",
  "Political Science",
  "Psychology",
  "Public Health",
  "Recreation, Parks, and Tourism Administration",
  "Sociology",
  "Software Engineering",
  "Spanish",
  "Statistics",
  "Theatre Arts",
  "Wine and Viticulture",
];
